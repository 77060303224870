<template>
	<v-tooltip v-if="mini" bottom>
		<template v-slot:activator="{ on }">
			<v-icon :color="color" v-on="on">{{ icon }}</v-icon>
		</template>
		<span v-text="text" />
	</v-tooltip>
	<w-layout v-else align-center justify-center>
		<v-icon :color="color">{{ icon }}</v-icon>
		<w-flex class="font-italic text" ml-3 v-text="text" />
	</w-layout>
</template>

<script>
export default {
	name: 'WTextAbstract',
	props: {
		mini: {
			default: false,
			required: false,
			type: Boolean
		},
		text: {
			default: '',
			required: false,
			type: String
		}
	}
}
</script>
