<script>
import WTextAbstract from '@/components/Commons/WTextAbstract'

/**
 * @displayName w-text-alert
 */
export default {
	name: 'WTextAlert',
	extends: WTextAbstract,
	data: function () {
		return {
			color: 'warning',
			icon: 'warning'
		}
	}
}
</script>
